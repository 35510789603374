import gql from 'graphql-tag'
import { PrivateUserFragment } from '../fragments/UserFragment'

export const CREATE_USER_FROM_TOKEN = gql`
  mutation CreateUserFromToken($input: CreateUserFromTokenInput!) {
    createUserFromToken(input: $input) {
      user {
        ...PrivateUserFragment
      }
    }
  }
  ${PrivateUserFragment}
`

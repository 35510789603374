/*
 * CODE FOR OAUTH TAKEN FROM HERE
 * https://github.com/aws-amplify/amplify-js/blob/e56aba642acc7eb3482f0e69454a530409d1b3ac/packages/auth/src/OAuth/OAuth.ts
 */

/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { parse } from 'url' // Used for OAuth parsing of Cognito Hosted UI
import * as oAuthStorage from './oauthStorage'

export type OAuthProvider =
  | 'COGNITO'
  | 'Google'
  | 'Facebook'
  | 'LoginWithAmazon'
  | 'SignInWithApple'

// const logger = console

function generateState(length: number) {
  let result = ''
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  for (let i = length; i > 0; --i) {
    result += chars[Math.round(Math.random() * (chars.length - 1))]
  }
  return result
}

function generateRandom(size: number) {
  const CHARSET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
  const buffer = new Uint8Array(size)
  if (typeof window !== 'undefined' && !!window.crypto) {
    window.crypto.getRandomValues(buffer)
  } else {
    for (let i = 0; i < size; i++) {
      buffer[i] = (Math.random() * CHARSET.length) | 0
    }
  }
  return bufferToString(buffer)
}

function bufferToString(buffer: Uint8Array) {
  const CHARSET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const state = []
  for (let i = 0; i < buffer.byteLength; i++) {
    const index = buffer[i] % CHARSET.length
    state.push(CHARSET[index])
  }
  return state.join('')
}

const SELF = '_self'

const launchUri = (url: string) => {
  const windowProxy = window.open(url, SELF)
  if (windowProxy) {
    return Promise.resolve(windowProxy)
  } else {
    return Promise.reject()
  }
}

async function generateChallenge(pkceKey: string) {
  // stolen from https://stackoverflow.com/a/59913241/3023931
  const hashBuffer = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(pkceKey)
  )
  const hashStr = String.fromCharCode.apply(
    null,
    (new Uint8Array(hashBuffer) as unknown) as number[]
  )
  const challenge = btoa(hashStr)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')

  return challenge
}

interface OAuthConstructorArgs {
  responseType: string
  domain: string
  cognitoClientId: string
  userPoolWebClientId: string
  scopes: string[]
  redirectSignInUrl: string
  redirectSignOutUrl: string
}

interface AuthResponse {
  accessToken: string
  refreshToken: string
  idToken: string
  state: string
}

export default class OAuth {
  private responseType: string
  private domain: string
  private cognitoClientId: string
  private userPoolWebClientId: string
  private scopes: string[]
  private redirectSignInUrl: string
  private redirectSignOutUrl: string

  constructor({
    responseType,
    domain,
    cognitoClientId,
    userPoolWebClientId,
    scopes = [],
    redirectSignInUrl,
    redirectSignOutUrl,
  }: OAuthConstructorArgs) {
    this.responseType = responseType
    this.domain = domain
    this.cognitoClientId = cognitoClientId
    this.userPoolWebClientId = userPoolWebClientId
    this.scopes = scopes
    this.redirectSignInUrl = redirectSignInUrl
    this.redirectSignOutUrl = redirectSignOutUrl
  }

  public async oauthSignIn({
    provider,
    customState,
  }: {
    provider: OAuthProvider
    customState?: string
  }) {
    const {
      domain,
      scopes,
      responseType: response_type,
      userPoolWebClientId: client_id,
      redirectSignInUrl,
    } = this
    const state = customState
      ? `${generateState(32)}-${customState}`
      : generateState(32)
    const pkce_key = generateRandom(128)

    oAuthStorage.setState(encodeURIComponent(state))
    oAuthStorage.setPKCE(pkce_key)

    const code_challenge = await generateChallenge(pkce_key)

    const scopesString = scopes.join(' ')

    const queryString = Object.entries({
      redirect_uri: redirectSignInUrl,
      response_type,
      client_id,
      identity_provider: provider,
      scope: scopesString,
      state,
      ...(response_type === 'code'
        ? { code_challenge, code_challenge_method: 'S256' }
        : {}),
    })
      .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      .join('&')

    const URL = `https://${domain}/oauth2/authorize?${queryString}`
    // logger.debug(`Redirecting to ${URL}`)
    launchUri(URL)
  }

  public async handleAuthResponse(currentUrl: string): Promise<AuthResponse> {
    // eslint-disable-next-line no-useless-catch
    try {
      const urlParams = currentUrl
        ? ({
            ...(parse(currentUrl).hash || '#')
              .substr(1)
              .split('&')
              .map((entry) => entry.split('='))
              .reduce((acc, [k, v]) => ((acc[k] = v), acc), {} as any),
            ...(parse(currentUrl).query || '')
              .split('&')
              .map((entry) => entry.split('='))
              .reduce((acc, [k, v]) => ((acc[k] = v), acc), {} as any),
          } as any)
        : {}
      const { error, error_description } = urlParams

      if (error) {
        try {
          // A bug in AWS prevents a user from linking an existing
          // Cognito username/password account with an IDP auth'ed account
          // and then auto-logging in the user. A workaround is to
          // catch the error and silently send the browser back to the IDP
          // and upon returning the session is correctly created

          // Catch this error and pull the IDP name out. Needs to be init caps.
          let provider = error_description
            .replace(/\+/g, ' ')
            .match(/Already found an entry for username (facebook|google)/i)[1]
          provider =
            provider.substring(0, 1).toUpperCase() + provider.substring(1)
          // Send the user back to the IDP
          this.oauthSignIn({ provider })
        } catch (err) {
          // console.log('Redirect: ' + err.message)
        }
        throw new Error(error_description)
      }

      const state: string = this._validateState(urlParams)

      // logger.debug(`Starting ${this.responseType} flow with ${currentUrl}`)
      if (this.responseType === 'code') {
        return {
          ...(await this._handleCodeFlow(currentUrl)),
          state,
        } as AuthResponse
      } else {
        return ({
          ...(await this._handleImplicitFlow(currentUrl)),
          state,
        } as unknown) as AuthResponse
      }
    } catch (e) {
      // logger.error(`Error handling auth response.`, e)
      throw e
    }
  }

  public async signOut() {
    const {
      cognitoClientId: client_id,
      redirectSignOutUrl: signout_uri,
      domain,
    } = this
    let oAuthLogoutEndpoint = 'https://' + domain + '/logout?'
    oAuthLogoutEndpoint += Object.entries({
      client_id,
      logout_uri: encodeURIComponent(signout_uri),
    })
      .map(([k, v]) => `${k}=${v}`)
      .join('&')

    // logger.debug(`Signing out from ${oAuthLogoutEndpoint}`)

    return launchUri(oAuthLogoutEndpoint)
  }

  private async _handleCodeFlow(currentUrl: string) {
    /* Convert URL into an object with parameters as keys
		{ redirect_uri: 'http://localhost:3000/', response_type: 'code', ...} */
    const { code } = (parse(currentUrl).query || '')
      .split('&')
      .map((pairings) => pairings.split('='))
      .reduce((accum, [k, v]) => ({ ...accum, [k]: v }), { code: undefined })

    if (!code) {
      return
    }

    const {
      userPoolWebClientId: client_id,
      redirectSignInUrl: redirect_uri,
      domain,
    } = this

    const oAuthTokenEndpoint = 'https://' + domain + '/oauth2/token'

    const code_verifier = oAuthStorage.getPKCE()

    const oAuthTokenBody = {
      grant_type: 'authorization_code',
      code,
      client_id,
      redirect_uri,
      ...(code_verifier ? { code_verifier } : {}),
    }

    // logger.debug(
    //   `Calling token endpoint: ${oAuthTokenEndpoint} with`,
    //   oAuthTokenBody
    // )
    const body = Object.entries(oAuthTokenBody)
      .map(
        ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v as any)}`
      )
      .join('&')

    const {
      access_token,
      refresh_token,
      id_token,
      error,
    } = await ((await fetch(oAuthTokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    })) as any).json()

    if (error) {
      throw new Error(error)
    }

    return {
      accessToken: access_token,
      refreshToken: refresh_token,
      idToken: id_token,
    }
  }

  private async _handleImplicitFlow(currentUrl: string) {
    // hash is `null` if `#` doesn't exist on URL
    const { id_token, access_token } = (parse(currentUrl).hash || '#')
      .substr(1) // Remove # from returned code
      .split('&')
      .map((pairings) => pairings.split('='))
      .reduce((accum, [k, v]) => ({ ...accum, [k]: v }), {
        id_token: undefined,
        access_token: undefined,
      })

    // logger.debug(`Retrieving implicit tokens from ${currentUrl} with`)

    return {
      accessToken: (access_token as unknown) as string,
      idToken: (id_token as unknown) as string,
      refreshToken: null,
    }
  }

  private _validateState(urlParams: any) {
    if (!urlParams) {
      return
    }

    const savedState = oAuthStorage.getState()
    const { state: returnedState } = urlParams

    // This is because savedState only exists if the flow was initiated by Amplify
    if (savedState && savedState !== returnedState) {
      throw new Error('Invalid state in OAuth flow')
    }
    return returnedState
  }
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import jwtDecode from 'jwt-decode'
import OAuth, { OAuthProvider } from './OAuth'
import { Router } from 'next/router'
import { hasPKCE } from './OAuth/oauthStorage'
import {
  CreateUserFromToken,
  CreateUserFromTokenVariables,
} from '../../types/generated/CreateUserFromToken'
import { getApolloClient } from '../apolloClient'
import { CREATE_USER_FROM_TOKEN } from '../../graphql/mutations/CreateUserFromToken'
import { CURRENT_USER_QUERY } from 'graphql/queries/CurrentUserQuery'

const aws = process.env.AWS as any
const {
  userPoolWebClientId,
  userPoolId,
  responseType,
  domain,
  redirectSignIn,
  redirectSignOut,
  scope: scopes,
} = aws.Auth

const oAuth = new OAuth({
  userPoolWebClientId: userPoolWebClientId,
  cognitoClientId: userPoolId,
  domain,
  scopes,
  redirectSignInUrl: redirectSignIn,
  redirectSignOutUrl: redirectSignOut,
  responseType: responseType,
})

export function federatedSignIn({
  provider,
  redirectUrl,
}: {
  provider: OAuthProvider
  redirectUrl: string
}) {
  sessionStorage.setItem('oauthRedirectUrl', redirectUrl)
  oAuth.oauthSignIn({ provider })
}

const apolloClient = getApolloClient()

export async function handleAuthResponse({
  url,
  router,
}: {
  url: string
  router: Router
}) {
  const redirectUrl = sessionStorage.getItem('oauthRedirectUrl') || '/'
  // in case you hit back in your browser we don't want to retry
  if (hasPKCE()) {
    const {
      idToken,
      accessToken,
      refreshToken,
    } = await oAuth.handleAuthResponse(url)

    const { email } = jwtDecode(idToken)
    const { data } = await apolloClient.mutate<
      CreateUserFromToken,
      CreateUserFromTokenVariables
    >({
      variables: {
        input: { email, idToken, accessToken, refreshToken },
      },
      mutation: CREATE_USER_FROM_TOKEN,
      refetchQueries: [{ query: CURRENT_USER_QUERY }],
    })

    if (!data) {
      throw new Error(`Unable to sign up with email ${email}`)
    }
  }
  router.push(redirectUrl)
}
